import React from "react"
import { graphql } from "gatsby"
import Navbar from "../components/sections/Navbar"
import ContactUsSection from "../components/sections/ContactUsSection"
import Hero from "../components/sections/Hero"
import { Container, Paper } from "@material-ui/core"
import SEO from "../components/seo"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <div className="basic-template page-{frontmatter.path}">

      <SEO
        title={frontmatter.meta_title}
        description={frontmatter.meta_description}
      />

      <Navbar />
      <Hero title={frontmatter.title} />
      <Container size="lg" className="basic-template__content-wrapper">
        <Paper elevation={0}>
          <div className="basic-template__content-container">
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>
        </Paper>
      </Container>
      <ContactUsSection />
    </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        meta_title
        meta_description
      }
    }
  }
`
